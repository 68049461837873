Px.CMS.UploadDialogComponent = class UploadDialogComponent extends Px.Component {

  template() {
    const t = this.getTextLabel;
    return Px.template`
      <dialog class="px-upload-dialog"
              ${this.dialogMode === 'closed' ? '' : 'open'}
              data-screen="${this.currentScreen}"
              data-oncancel="onCancel">
        <button class="px-ud-close" data-onclick="onCancel">
          ${t('back-button-title')}
        </button>
        ${this.render_screen_template(this.currentScreen)}
      </dialog>
    `;
  }

  render_screen_template(screen) {
    switch (screen) {
    case 'sources':
      return this.sources_screen_template();
    case 'filelist':
      return this.filelist_screen_template();
    case 'progress':
      return this.progress_screen_template();
    case 'picker-galleries':
      return this.picker_galleries_screen_template();
    case 'picker-url':
      return this.picker_url_screen_template();
    case 'picker-camera':
      return this.picker_camera_screen_template();
    default:
      throw new Error(`Don't know how to render screen: ${this.currentScreen}`);
    }
  }

  sources_screen_template() {
    const t = this.getTextLabel;
    return Px.template`
      <div class="px-ud-wrapper">
        <div class="px-ud-header">
          <h6>
            ${t('main-panel-title')}
          </h6>
        </div>
        <div class="px-ud-body">
          <div class="px-ud-sources">
            ${this.uploadSources.filter(source => source !== 'qr').map(source => {
              return this.picker_source_button_template(source);
            })}
          </div>
          ${Px.if(this.uploadSources.includes('qr'), () => {
            const show_spinner = this.state.qr_upload_token_is_loading || (this.state.qr_upload_status === 'initialized');
            return Px.template`
              <div class="px-ud-picker-qr">
                <hr/>
                <p>
                  ${t('qr-picker-info-text')}
                </p>
                <div class="px-ud-qrcode" data-upload-status="${this.state.qr_upload_status}">
                  ${Px.if(this.state.qr_code_url, () => {
                    return Px.template`
                      <img src="${this.state.qr_code_url}" />
                    `;
                  })}
                  <div class="px-ud-spinner" ${show_spinner ? '' : 'hidden'}></div>
                </div>
              </div>
            `;
          })}
          ${Px.if(this.uploadSources.includes('local'), () => {
            return Px.template`
              <input type="file"
                    hidden
                    ${this.data.multiple ? 'multiple' : ''}
                    accept="${this.SUPPORTED_FILE_TYPES.join(',')}"
                    data-onchange="addSelectedLocalFiles"
                    data-oncancel="onCancel"
              />
            `;
          })}
        </div>
      </div>
    `;
  }

  picker_source_button_template(source) {
    const t = this.getTextLabel;
    const class_name = this.getSourceButtonClassName(source);

    switch (source) {
    case 'local':
      return Px.template`
        <button class="${class_name}" data-source="local" data-onclick="activateFileInput">
          ${t('source-button-local-text')}
        </button>
      `;
    case 'galleries':
      return Px.template`
        <button class="${class_name}" data-source="galleries" data-onclick="activateGalleryPicker">
          ${t('source-button-galleries-text')}
        </button>
      `;
    case 'url':
      return Px.template`
        <button class="${class_name}" data-source="url" data-onclick="activateUrlPicker">
          ${t('source-button-url-text')}
        </button>
      `;
    case 'camera':
      return Px.template`
        <button class="${class_name}" data-source="camera" data-onclick="activateCameraPicker">
          ${t('source-button-camera-text')}
        </button>
      `;
    default:
      console.error(`Unsupported image source: ${source}`);
    }
  }

  filelist_screen_template() {
    const t = this.getTextLabel;
    return Px.template`
      <div class="px-ud-header">
        <h6>
          ${t('main-panel-title')}
        </h6>
      </div>
      <ul class="px-ud-filelist">
        ${this.state.files.map(file => {
          return Px.template`
            <li>
              <span class="px-ud-filename">
                ${file.name}
              </span>
              <span class="px-ud-error">
                ${file.uploadError || ''}
              </span>
            </li>
          `;
        })}
      </ul>
    `;
  }

  progress_screen_template() {
    const upload_info = this.uploadProgressInfo;
    let upload_info_text = null;
    if (upload_info) {
      if (upload_info.total_count > 1) {
        upload_info_text = this.getTextLabel('upload-info-extended-text', {
          count: Math.min(upload_info.uploaded_count + 1, upload_info.total_count),
          total: upload_info.total_count
        });
      } else {
        upload_info_text = this.getTextLabel('upload-info-text');
      }
    }
    return Px.template`
      <div class="px-ud-progress">
        <div class="px-ud-spinner"></div>
        ${Px.if(upload_info_text, () => {
          return Px.template`
            <div class="px-upload-info">
              ${upload_info_text}
            </div>
          `;
        })}
      </div>
    `;
  }

  picker_galleries_screen_template() {
    return this.renderChild(UploadDialogComponent.GalleryPicker, 'picker-camera', {
      addFiles: this.addFiles,
      onClose: this.closePicker,
      multiple: this.data.multiple,
      max_files: this.data.max_files,
      getTextLabel: this.getTextLabel,
      getButtonClassName: this.getButtonClassName
    });
  }

  picker_url_screen_template() {
    return this.renderChild(UploadDialogComponent.UrlPicker, 'picker-url', {
      addFiles: this.addFiles,
      updateFile: this.updateFile,
      onClose: this.closePicker,
      getTextLabel: this.getTextLabel,
      getButtonClassName: this.getButtonClassName
    });
  }

  picker_camera_screen_template() {
    return this.renderChild(UploadDialogComponent.CameraPicker, 'picker-camera', {
      addFiles: this.addFiles,
      onClose: this.closePicker,
      getTextLabel: this.getTextLabel,
      getButtonClassName: this.getButtonClassName
    });
  }

  get dataProperties() {
    return {
      sources: {std: 'local'},
      multiple: {std: false},
      max_upload_size: {std: '50Mb'},
      max_files: {std: null},
      gallery_id: {std: null},
      text_labels: {std: {}},
      button_classes: {std: {}},
      onCancel: {std: () => {}},
      onUploadStart: {std: () => {}},
      onUploadProgress: {std: () => {}},
      onUploadError: {std: () => {}},
      onUploadSuccess: {std: () => {}},
    };
  }

  static get properties() {
    return {
      files: {type: 'array', std: mobx.observable.array()},
      active_picker: {type: 'str', std: null},
      // QR code source
      qr_upload_token_is_loading: {type: 'bool', std: false},
      qr_upload_token: {type: 'str', std: null},
      qr_code_url: {type: 'str', std: null},
      qr_upload_status: {type: 'str', std: 'unknown'},
      qr_uploaded_images: {type: 'array', std: mobx.observable.array()}
    };
  }

  constructor(props) {
    super(props);

    this.QR_UPLOAD_TRACK_TIMEOUT_MS = 3000;
    this.MAX_UPLOAD_ATTEMPTS = 2;
    this.UPLOAD_CONCURRENCY = 2;
    this.SUPPORTED_FILE_TYPES = [
      'image/png', '.png',
      'image/jpeg', '.jpg', '.jpeg',
      'image/webp', '.webp',
      'image/heic', '.heic',
      'application/pdf', '.pdf',
    ];
    this.DEFAULT_TEXT_LABELS = {
      'error-message-filesize': 'File is too large. Maximum allowed size is {{max_size}}',
      'error-message-upload': 'Upload failed',
      'back-button-title': 'Back',
      'close-button-title': 'Close',
      'main-panel-title': 'Upload Photos',
      'upload-info-text': 'Uploading...',
      'upload-info-extended-text': 'Uploading {{count}} of {{total}}...',
      'galleries-picker-title': 'My Galleries',
      'url-picker-title': 'URL Link',
      'camera-picker-title': 'Camera',
      'source-button-local-text': 'This Device',
      'source-button-galleries-text': 'My Galleries',
      'source-button-url-text': 'URL Link',
      'source-button-camera-text': 'From Camera',
      'picker-load-more-button-title': 'Load More',
      'picker-ok-button-title': 'OK',
      'qr-picker-info-text': 'Scan QR code to upload images from your phone:',
      'no-galleries-text': 'You do not have any galleries.',
      'no-images-text': 'There are no images in this gallery.'
    };

    this._qrUploadsTrackTimeout = null;

    this.addFiles = this.addFiles.bind(this);
    this.updateFile = this.updateFile.bind(this);
    this.getTextLabel = this.getTextLabel.bind(this);
    this.getButtonClassName = this.getButtonClassName.bind(this);
    this.closePicker = this.closePicker.bind(this);
    this.trackQrUploads = this.trackQrUploads.bind(this);

    this.registerReaction(() => this.dialogMode, mode => this.toggleDialog(mode), {
      name: 'Px.CMS.UploadDialogComponent::toggleDialogMode'
    });

    this.on('mount', () => {
      this.toggleDialog(this.dialogMode);
      // Automatically activate the first source, if there is only one (and it's not QR).
      if (this.uploadSources.length === 1 && this.uploadSources[0] !== 'qr') {
        this.dom_node.querySelector('.px-ud-sources button').click();
      }
    });

    if (this.uploadSources.includes('qr')) {
      this.fetchQrToken();
    }
  }

  static get computedProperties() {
    return {
      currentScreen: function() {
        if (this.state.files.length === 0) {
          return this.state.active_picker || 'sources';
        } else {
          if (this.state.files.some(f => f.uploadInProgress)) {
            return 'progress';
          }
          // All files have finished uploading (either successfully or failed with an error).
          if (this.state.files.some(f => f.uploadError)) {
            // If we have any upload errors, we display the filelist.
            return 'filelist';
          } else if (this.state.files.some(f => f.source === 'qr') && this.data.multiple) {
            // If we're using the QR uploader we might keep receiving new files even though no uploads
            // are currently in progress.
            return 'progress';
          }
          return 'filelist';
        }
      },
      dialogMode: function() {
        // When opening the dialog and there is only one source, there is no need to display
        // the sources panel since we click the button programatically.
        // This avoids a flash of unnecessary dialog display, which is especially noticeable
        // and somewhat confusing when using the local file picker, which takes a few hundred ms to start up.
        if (this.currentScreen === 'sources' && this.uploadSources.length === 1 && this.uploadSources[0] !== 'qr') {
          return 'closed';
        }
        if (this.currentScreen === 'progress') {
          return 'non-modal';
        }
        return 'modal';
      },
      uploadSources: function() {
        let sources = this.data.sources.split(' ').map(s => s.trim());
        // Disable qr source if we are on a mobile device.
        if (window.matchMedia('(max-device-width: 599px), (max-device-height: 599px)').matches) {
          sources = sources.filter(src => src !== 'qr');
        }
        return sources;
      },
      maxFileSize: function() {
        return this.convertMaxSizeToBytes(this.data.max_upload_size);
      },
      uploadProgressInfo: function() {
        let total_count = this.state.files.length;
        let uploaded_count = 0;
        let indeterminate = false;
        this.state.files.forEach(file => {
          if (file.uploadProgress.lengthComputable) {
            if (file.uploadResult) {
              uploaded_count += 1;
            }
          } else {
            indeterminate = true;
          }
        });
        if (indeterminate) {
          return null;
        } else {
          return {
            uploaded_count: uploaded_count,
            total_count: total_count
          };
        }
      }
    };
  }

  toggleDialog(mode) {
    const dialog = this.dom_node;
    if (dialog) {
      switch (mode) {
      case 'closed':
        if (dialog.open) {
          dialog.close();
        }
        break;
      case 'modal':
        if (!dialog.matches(':modal')) {
          if (dialog.open) {
            dialog.close();
          }
          dialog.showModal();
        }
        break;
      case 'non-modal':
        if (dialog.matches(':modal')) {
          dialog.close();
        }
        if (!dialog.open) {
          dialog.show();
        }
        break;
      default:
        throw new Error(`Invalid dialog mode: ${mode}`);
      }
    }
  }

  async fetchQrToken() {
    if (this.state.qr_upload_token_is_loading) {
      return;
    }

    this.stopTrackingQrUploads();

    mobx.runInAction(() => {
      this.state.qr_upload_token_is_loading = true;
      this.state.qr_upload_token = null;
      this.state.qr_code_url = null;
      this.state.qr_upload_status = 'unknown';
    });

    let url = '/upload/qr_code.json';

    const params = new URLSearchParams();
    params.set('max_file_size', this.maxFileSize);

    if (this.data.gallery_id) {
      params.set('gallery_id', this.data.gallery_id);
    }
    if (!this.data.multiple) {
      params.set('max_files', 1);
    } else if (this.data.max_files) {
      params.set('max_files', this.data.max_files);
    }

    if (params.size > 0) {
      url += '?' + params.toString();
    }

    try {
      const json = await fetch(url, {method: 'POST'}).then(r => r.json());
      mobx.runInAction(() => {
        this.state.qr_code_url = json.code_src;
        this.state.qr_upload_token = json.upload_token;
      });
      this._qrUploadsTrackTimeout = setTimeout(this.trackQrUploads, this.QR_UPLOAD_TRACK_TIMEOUT_MS);
    } finally {
      this.state.qr_upload_token_is_loading = false;
    }
  }

  async trackQrUploads() {
    if (this.destroyed) {
      return;
    }

    let status;
    try {
      status = await fetch(`/upload/upload_status.json?upload_token=${this.state.qr_upload_token}`).then(r => r.json());
    } finally {
      this._qrUploadsTrackTimeout = setTimeout(this.trackQrUploads, this.QR_UPLOAD_TRACK_TIMEOUT_MS);
    }

    if (!status) {
      return;
    }

    const newImages = status.images.slice(this.state.qr_uploaded_images.length);

    mobx.runInAction(() => {
      this.state.qr_upload_status = status.status;
      this.state.qr_uploaded_images.replace(status.images);
    });

    if (newImages.length > 0) {
      const images = newImages.map(image => ['qr', image.filename, {uploadResult: image}]);
      this.addFiles(images);
    }
  };

  stopTrackingQrUploads() {
    clearTimeout(this._qrUploadsTrackTimeout);
  }

  addFiles(files) {
    const insertion_idx = this.state.files.length;
    files.forEach(([source, name, attrs]) => this.addFile(source, name, attrs));
    setTimeout(() => this.triggerUploads());
    return insertion_idx;
  }

  addFile(source, name, overrides) {
    this.state.files.push({
      source: source,
      name: name,
      data: {},
      uploadInProgress: false,
      uploadAttempts: 0,
      uploadProgress: {
        lengthComputable: false,
        loaded: 0,
        total: 0
      },
      uploadError: null,
      uploadResult: null
    });

    const idx = this.state.files.length - 1;
    this.updateFile(idx, overrides);
  }

  updateFile(idx, updates) {
    const file = this.state.files[idx];

    const is_success = updates.uploadResult && !file.uploadResult;
    const is_error = updates.uploadError && !file.uploadError;
    const is_upload_start = updates.uploadInProgress && !file.uploadInProgress;
    const is_upload_progress = updates.uploadProgress && (file.uploadInProgress || updates.uploadInProgress) && (
      updates.uploadProgress.lengthComputable !== file.uploadProgress.lengthComputable ||
        updates.uploadProgress.loaded !== file.uploadProgress.loaded ||
        updates.uploadProgress.total !== file.uploadProgress.total
    );

    Object.entries(updates).forEach(([key, val]) => {
      file[key] = val;
    });

    if (is_success) {
      this.data.onUploadSuccess(this.buildCallbackPayload(idx));
    }
    if (is_error) {
      this.data.onUploadError(this.buildCallbackPayload(idx));
    }
    if (is_upload_start) {
      this.data.onUploadStart(this.buildCallbackPayload(idx));
    }
    if (is_upload_progress) {
      this.data.onUploadProgress(this.buildCallbackPayload(idx));
    }

    if (is_success || is_error) {
      setTimeout(() => this.triggerUploads());
    }
  }

  triggerUploads() {
    const filesToUpload = this.UPLOAD_CONCURRENCY - this.state.files.filter(f => f.uploadInProgress).length;
    let nextUploads = [];

    this.state.files.forEach((file, idx) => {
      if (nextUploads.length < filesToUpload) {
        if (!(file.uploadResult || file.uploadError || file.uploadInProgress)) {
          nextUploads.push([idx, file]);
        }
      }
    });

    nextUploads.forEach(([idx, file]) => {
      this.updateFile(idx, {
        uploadInProgress: true,
        uploadProgress: {
          lengthComputable: file.uploadProgress.lengthComputable,
          total: file.uploadProgress.total,
          loaded: 0
        },
        uploadAttempts: file.uploadAttempts + 1
      });
      this.postImage(file.data.file, idx);
    });

    // Check whether we should close/destroy the dialog.
    if (this.state.files.some(f => f.uploadInProgress)) {
      // We never close the dialog while uploads are still in progress.
    } else {
      // All files have finished uploading (either successfully or failed with an error).
      if (this.state.files.some(f => f.uploadError)) {
        // If we have any upload errors, we display the filelist and don't automatically close the dialog.
      } else if (this.state.files.some(f => f.source === 'qr') && this.data.multiple) {
        // If we're using the QR uploader with multiple uploads enabled, we have to wait until QR upload status
        // switches to 'uploaded' or 'unknown' before destroying the uploader since we might keep receiving
        // new files from the QR uploader even if all current ones are successfully uploaded.
        if (this.state.qr_upload_status === 'unknown' || this.state.qr_upload_status === 'uploaded') {
          this.destroy();
        }
      } else {
        // Otherwise we are done and we close the uploader.
        this.destroy();
      }
    }
  }

  buildCallbackPayload(changed_idx) {
    const files = this.state.files.map(file => {
      return {
        progress: {
          lengthComputable: file.uploadProgress.lengthComputable,
          loaded: file.uploadProgress.loaded,
          total: file.uploadProgress.total
        },
        error: file.uploadError,
        result: mobx.toJS(file.uploadResult)
      };
    });

    return {
      files: files,
      changedFile: mobx.toJS(files[changed_idx])
    };
  }

  emitEvent(eventType, changed_idx) {
    if (this.destroyed) {
      return;
    }

    const options = {};
    if (Number.isInteger(changed_idx)) {
      options.detail = this.buildCallbackPayload(changed_idx);
    }
    const event = new CustomEvent(eventType, options);
    return this.dispatchEvent(event);
  }

  convertMaxSizeToBytes(max_size_str) {
    const match = max_size_str.match(/^(\d+(\.\d+)?)([a-zA-Z]*)?$/);
    const number = parseFloat(match[1]);
    const unit = (match[3] || 'b').toLowerCase();

    let bytes;

    switch (unit) {
    case 'm':
    case 'mb':
      bytes = number * 1024 * 1024;
      break;
    case 'k':
    case 'kb':
      bytes = number * 1024;
      break;
    default:
      bytes = number;
    }

    return bytes;
  }

  getTextLabel(key, variables) {
    let text = this.data.text_labels[key] || this.DEFAULT_TEXT_LABELS[key];

    Object.entries(variables || {}).forEach(([key, value]) => {
      text = text.replaceAll(`{{${key}}}`, value);
    });

    return Px.Util.escapeHTML(text);
  }

  getButtonClassName(...keys) {
    const class_list = [];

    ['button-class', ...keys].forEach(key => {
      if (this.data.button_classes[key]) {
        class_list.push(this.data.button_classes[key]);
      }
    });

    return class_list.join(' ');
  }

  getSourceButtonClassName(source) {
    let class_name = this.getButtonClassName('source-button-class');
    if (this.data.button_classes[`${source}-source-button-class`]) {
      class_name += ' ' + this.data.button_classes[`${source}-source-button-class`];
    }
    return class_name;
  }

  postImage(file, idx) {
    if (this.destroyed) {
      return;
    }

    const formData = new FormData();

    formData.append('data', file);

    const ajax = new XMLHttpRequest();

    ajax.addEventListener('load', this.onXhrLoad.bind(this, idx), false);
    ajax.upload.addEventListener('progress', this.onUploadProgress.bind(this, idx), false);
    ajax.addEventListener('readystatechange', () => this.onReadyStateChange(idx, ajax), false);

    let uploadUrl = '/upload/image';
    if (this.data.gallery_id) {
      uploadUrl += '?gallery_id=' + this.data.gallery_id;
    }

    ajax.open('POST', uploadUrl);
    ajax.send(formData);
  }

  onReadyStateChange(idx, ajax) {
    if (ajax.readyState !== 4) {
      return;
    }

    let status = ajax.status ? ajax.status : 0;
    let res;

    try {
      res = JSON.parse(ajax.responseText);
    } catch (e) { }

    if (status >= 400) {
      const errorMessage = this.getTextLabel('error-message-upload');

      this.onUploadError(idx, errorMessage);
    } else {
      if (res) {
        this.onUploadSuccess(idx, res);
      } else {
        let responseErrorMessage = ajax.responseText;

        if (responseErrorMessage) {
          console.error('Upload error', responseErrorMessage);
        }

        const errorMessage = this.getTextLabel('error-message-upload');
        this.onUploadError(idx, errorMessage);
      }
    }
  }

  onUploadSuccess(idx, response) {
    this.updateFile(idx, {
      uploadInProgress: false,
      uploadResult: response
    });
    this.triggerUploads();
  }

  onUploadError(idx, errorMessage) {
    this.updateFile(idx, {uploadInProgress: false});
    const file = this.state.files[idx];
    if (file.uploadAttempts >= this.MAX_UPLOAD_ATTEMPTS) {
      this.updateFile(idx, {uploadError: errorMessage});
    }
    this.triggerUploads();
  }

  onUploadProgress(idx, evt) {
    this.updateFile(idx, {
      uploadProgress: {
        lengthComputable: evt.lengthComputable,
        loaded: evt.loaded,
        total: evt.total
      }
    });
  }

  // The only reason we listen to the load event is to make sure we always reach 100 percent.
  // The progress event is not guaranteed to always fire at 100%.
  onXhrLoad(idx, evt) {
    if (evt.lengthComputable) {
      this.updateFile(idx, {
        uploadProgress: {
          lengthComputable: evt.lengthComputable,
          loaded: evt.loaded,
          total: evt.total
        }
      });
    }
  }

  // --------------
  // Event handlers
  // --------------

  onCancel() {
    this.data.onCancel();
    this.destroy();
  }

  closePicker(evt) {
    this.state.active_picker = null;
  }

  activateFileInput(evt) {
    this.dom_node.querySelector('input[type="file"]').click();
  }

  addSelectedLocalFiles(evt) {
    evt.stopPropagation();

    const sizeErrorMessage = this.getTextLabel('error-message-filesize', {
      max_size: this.data.max_upload_size
    });

    const files = [];

    for (const file of evt.target.files) {
      const attrs = {
        data: {file: file},
        // Local files always have a computable length, make sure to set lengthComputable to `true`
        // immediately when adding new files so that progress bar doesn't keep alternating between
        // determinate and indeterminate versions.
        uploadProgress: {
          lengthComputable: true,
          total: file.size,
          loaded: 0
        }
      };

      if (file.size > this.maxFileSize) {
        attrs.uploadError = sizeErrorMessage;
      }

      files.push(['local', file.name, attrs]);
    }

    this.addFiles(files);
  }

  activateGalleryPicker(evt) {
    this.state.active_picker = 'picker-galleries';
  }

  activateUrlPicker(evt) {
    this.state.active_picker = 'picker-url';
  }

  activateCameraPicker(evt) {
    this.state.active_picker = 'picker-camera';
  }

};

Px.CMS.UploadDialogComponent.GalleryPicker = class GalleryPicker extends Px.Component {

  template() {
    if (this.state.current_gallery) {
      return this.gallery_contents_template();
    } else {
      return this.gallery_list_template();
    }
  }

  gallery_list_template() {
    const t = this.data.getTextLabel;
    return Px.template`
      <div class="px-ud-wrapper">
        <div class="px-ud-header">
          <button class="px-ud-back" data-onclick="onClose">
            &lt;
          </button>
          <h6>
            ${t('galleries-picker-title')}
          </h6>
        </div>
        <div class="px-ud-body">
          <div class="px-ud-galleries">
            ${Px.if(this.state.galleries.length === 0 && !this.state.is_loading_galleries, () => {
              return Px.template`
                <p class="px-ud-gallery-no-items">${t('no-galleries-text')}</p>
              `;
            }).else(() => {
              return this.state.galleries.map(gallery => {
                return Px.template`
                  <div class="px-ud-gallery"
                      data-id="${gallery.id}"
                      data-name="${gallery.name}"
                      data-onclick="selectGallery"
                      title="${gallery.name}"
                      style="background-image:url('${gallery.preview}')">
                  </div>
                `;
              });
            })}
          </div>
          <div class="px-ud-progress" ${this.state.is_loading_galleries ? '' : 'hidden'}>
            <div class="px-ud-spinner"></div>
          </div>
          <div class="px-ud-load-more"
              ${this.state.has_more_galleries && !this.state.is_loading_galleries ? '' : 'hidden'}>
            <button class="${this.data.getButtonClassName('picker-load-more-button-class')}"
                    data-onclick="fetchMoreGalleries">
              ${t('picker-load-more-button-title')}
            </button>
          </div>
        </div>
      </div>
    `;
  }

  gallery_contents_template() {
    const t = this.data.getTextLabel;

    let buttons = '';

    return Px.template`
      <div class="px-ud-wrapper">
        <div class="px-ud-header">
          <button class="px-ud-back" data-onclick="backToGalleries">
            ${t('galleries-picker-title')}
          </button>
          <h6>
            ${this.state.current_gallery.name}
          </h6>
        </div>
        <div class="px-ud-body">
          <div class="px-ud-images">
            ${Px.if(this.state.images.length === 0 && !this.state.is_loading_images, () => {
              return Px.template`
                <p class="px-ud-gallery-no-items">${t('no-images-text')}</p>
              `;
            }).else(() => {
              return this.state.images.map(image => {
                return Px.template`
                  <div class="px-ud-image"
                      data-id="${image.id}"
                      data-selected="${this.state.selected_images.includes(image.id)}"
                      data-filename="${image.filename}"
                      data-onclick="selectGalleryImage"
                      style="background-image:url('${image.thumbnails[0].url}')">
                  </div>
                `;
              });
            })}
          </div>
          <div class="px-ud-progress" ${this.state.is_loading_images ? '' : 'hidden'}>
            <div class="px-ud-spinner"></div>
          </div>
          <div class="px-ud-load-more" ${this.state.has_more_images && !this.state.is_loading_images ? '' : 'hidden'}>
            <button class="${this.data.getButtonClassName('picker-load-more-button-class')}"
                    data-onclick="fetchMoreImages">
              ${t('picker-load-more-button-title')}
            </button>
          </div>
        </div>
        ${Px.if(this.data.multiple, () => {
          return Px.template`
            <div class="px-ud-footer">
              <button class="${this.data.getButtonClassName('confirm-button-class')}"
                      data-onclick="confirmGalleryPickerSelection">
                ${t('picker-ok-button-title')}
              </button>
            </div>
          `;
        })}
      </div>
    `;
  }

  constructor(props) {
    super(props);

    // NOTE: This must be kept in sync with Gallery::per_page.
    this.PER_PAGE_GALLERIES = 50;
    // NOTE: This must be kept in sync with Image::per_page.
    this.PER_PAGE_IMAGES = 5000;

    this.fetchGalleries();
  }

  get dataProperties() {
    return {
      addFiles: {},
      onClose: {},
      multiple: {std: false},
      max_files: {std: null},
      getTextLabel: {std: t => t},
      getButtonClassName: {std: t => t}
    };
  }

  static get properties() {
    return {
      galleries: {type: 'array', std: mobx.observable.array()},
      images: {type: 'array', std: mobx.observable.array()},
      current_gallery: {type: 'obj', std: null},
      has_more_galleries: {type: 'bool', std: false},
      has_more_images: {type: 'bool', std: false},
      is_loading_galleries: {type: 'bool', std: false},
      is_loading_images: {type: 'bool', std: false},
      selected_images: {type: 'array', std: mobx.observable.array()},
      last_selected_range: {type: 'obj', std: null}
    };
  }

  // -------
  // Private
  // -------

  async fetchGalleries() {
    if (this.state.is_loading_galleries) {
      return;
    }

    this.state.is_loading_galleries = true;

    const page = Math.floor(this.state.galleries.length / this.PER_PAGE_GALLERIES) + 1;

    const url = `/v1/galleries/_mine.json?page=${page}`;
    try {
      const galleries = await fetch(url).then(r => r.json());
      mobx.runInAction(() => {
        galleries.forEach(gallery => {
          this.state.galleries.push(gallery);
        });
        this.state.has_more_galleries = galleries.length === this.PER_PAGE_GALLERIES;
      });
    } finally {
      this.state.is_loading_galleries = false;
    }
  }

  async fetchImages() {
    if (this.state.is_loading_images) {
      return;
    }

    this.state.is_loading_images = true;
    this.abortController = new AbortController();

    const page = Math.floor(this.state.images.length / this.PER_PAGE_IMAGES) + 1;
    const gallery_id = this.state.current_gallery.id;

    const url = `/v1/galleries/${gallery_id}/images.json?page_size=${this.PER_PAGE_IMAGES}&page=${page}`;
    try {
      const images = await fetch(url, {signal: this.abortController.signal}).then(r => r.json());
      mobx.runInAction(() => {
        images.forEach(image => {
          this.state.images.push(image);
        });
        this.state.has_more_galleries = images.length === this.PER_PAGE_IMAGES;
      });
    } finally {
      this.state.is_loading_images = false;
    }
  }

  selectImage(image) {
    if (!this.state.selected_images.includes(image.id)) {
      mobx.runInAction(() => {
        if (this.data.max_files && this.state.selected_images.length === this.data.max_files) {
          this.state.selected_images.shift();
        }
        this.state.selected_images.push(image.id);
      });
    }
  }

  deselectImage(image) {
    const index = this.state.selected_images.indexOf(image.id);
    if (index !== -1) {
      this.state.selected_images.splice(index, 1);
    }
  }

  toggleImageSelected(image) {
    this.state.last_selected_range = null;

    if (this.state.selected_images.includes(image.id)) {
      this.deselectImage(image);
    } else {
      this.selectImage(image);
    }
  }

  toggleImageRangeSelected(image) {
    if (this.state.selected_images.length === 0) {
      // Select everything up to the clicked image.
      const end_idx = this.state.images.indexOf(image);
      this.state.images.slice(0, end_idx + 1).forEach(image => this.selectImage(image));
      this.state.last_selected_range = [0, end_idx];
      return;
    }

    let image_a;
    const last_range = this.state.last_selected_range;
    if (last_range) {
      image_a = this.state.images[last_range[0]];
      // Clear previously selected range.
      const idx_min = Math.min(last_range[0], last_range[1]);
      const idx_max = Math.max(last_range[0], last_range[1]);
      this.state.images.forEach((image, idx) => {
        if (idx >= idx_min && idx <= idx_max) {
          this.deselectImage(image);
        }
      });
    } else {
      const last_selected_image_id = this.state.selected_images[this.state.selected_images.length - 1];
      image_a = this.state.images.find(image => image.id === last_selected_image_id);
    }

    const idx_a = this.state.images.indexOf(image_a);
    const idx_b = this.state.images.indexOf(image);
    const idx_min = Math.min(idx_a, idx_b);
    const idx_max = Math.max(idx_a, idx_b);

    this.state.images.forEach((image, idx) => {
      if (idx >= idx_min && idx <= idx_max) {
        this.selectImage(image);
      }
    });

    this.state.last_selected_range = [idx_a, idx_b];
  }

  // --------------
  // Event handlers
  // --------------

  onClose(evt) {
    this.data.onClose();
  }

  backToGalleries() {
    mobx.runInAction(() => {
      this.state.current_gallery = null;
      this.state.images.clear();
      this.state.selected_images.clear();
      this.state.last_selected_range = null;
    });
  }

  selectGallery(evt) {
    const gallery_id = evt.target.closest('[data-id]').getAttribute('data-id');
    const gallery = this.state.galleries.find(g => g.id.toString() === gallery_id);
    mobx.runInAction(() => {
      this.state.current_gallery = gallery;
      this.state.images.clear();
      this.state.selected_images.clear();
      this.state.last_selected_range = null;
    });
    this.fetchImages();
  }

  fetchMoreGalleries(evt) {
    this.fetchGalleries();
  }

  fetchMoreImages(evt) {
    this.fetchImages();
  }

  selectGalleryImage(evt) {
    const image_id = evt.target.closest('[data-id]').getAttribute('data-id');
    const image = this.state.images.find(i => i.id.toString() === image_id);

    if (this.data.multiple && evt.shiftKey) {
      this.toggleImageRangeSelected(image);
    } else {
      // Toggle selection.
      this.toggleImageSelected(image);
    }

    if (!this.data.multiple) {
      this.confirmGalleryPickerSelection();
    }
  }

  confirmGalleryPickerSelection() {
    const files = [];
    this.state.images.forEach(image => {
      if (this.state.selected_images.includes(image.id)) {
        files.push(['galleries', image.filename, {uploadResult: image}]);
      }
    });
    this.data.addFiles(files);
  }

};

Px.CMS.UploadDialogComponent.UrlPicker = class UrlPicker extends Px.Component {

  template() {
    const t = this.data.getTextLabel;
    return Px.template`
      <div class="px-ud-wrapper">
        <div class="px-ud-header">
          <button class="px-ud-back" data-onclick="onClose">
            ${t('main-panel-title')}
          </button>
          <h6>
            ${t('url-picker-title')}
          </h6>
        </div>
        <div class="px-ud-body">
          <div class="px-ud-url-input">
            <input type="url" required data-onkeypress="confirmUrlUploadOnEnter"/>
          </div>
        </div>
        <div class="px-ud-footer">
          <button class="${this.data.getButtonClassName('confirm-button-class')}"
                  ${this.state.disabled ? 'disabled' : ''}
                  data-onclick="confirmUrlUpload">
            ${t('picker-ok-button-title')}
          </button>
        </div>
      </div>
    `;
  }

  constructor(props) {
    super(props);
    this.on('mount', () => this.focusUrlInput());
  }

  get dataProperties() {
    return {
      addFiles: {},
      updateFile: {},
      onClose: {},
      getTextLabel: {std: t => t},
      getButtonClassName: {std: t => t}
    };
  }

  static get properties() {
    return {
      disabled: {type: 'bool', std: false}
    };
  }

  // -------
  // Private
  // -------

  focusUrlInput() {
    const input = this.dom_node.querySelector('.px-ud-url-input input');
    input.focus();
  }

  // --------------
  // Event handlers
  // --------------

  onClose(evt) {
    this.data.onClose();
  }

  confirmUrlUploadOnEnter(evt) {
    if (evt.key === 'Enter') {
      this.confirmUrlUpload();
    }
  }

  async confirmUrlUpload(evt) {
    const input = this.dom_node.querySelector('.px-ud-url-input input');

    if (input.reportValidity()) {
      const url = input.value.trim();
      const splat = url.split('/');
      const filename = splat[splat.length - 1];

      this.state.disabled = true;

      const idx = this.data.addFiles([['url', filename, {uploadInProgress: true}]]);

      const response = await fetch('/upload/image/url', {
        method: 'POST',
        body: new URLSearchParams({
          url: url,
          name: filename
        })
      });

      if (response.status === 200) {
        const result = await response.json();
        this.data.updateFile(idx, {uploadResult: result, uploadInProgress: false});
      } else {
        const errorMessage = this.data.getTextLabel('error-message-upload');
        this.data.updateFile(idx, {uploadError: errorMessage, uploadInProgress: false});
      }
    }
  }

};

Px.CMS.UploadDialogComponent.CameraPicker = class CameraPicker extends Px.Component {

  template() {
    const t = this.data.getTextLabel;
    return Px.template`
      <div class="px-ud-wrapper">
        <div class="px-ud-header">
          <button class="px-ud-back" data-onclick="onClose">
            ${t('main-panel-title')}
          </button>
          <h6>
            ${t('camera-picker-title')}
          </h6>
        </div>
        <div class="px-ud-body">
          <div class="px-ud-camera-preview">
            <video playsinline></video>
          </div>
          ${Px.if(this.state.error, () => {
            return Px.template`
              <p>${this.state.error}</p>
            `;
          })}
        </div>
        <div class="px-ud-footer">
          <button class="${this.data.getButtonClassName('confirm-button-class')}"
                  ${this.state.disabled ? 'disabled' : ''}
                  data-onclick="captureCameraImage">
            ${t('picker-ok-button-title')}
          </button>
        </div>
      </div>
    `;
  }

  constructor(props) {
    super(props);
    this.on('mount', () => this.startVideoStream());
  }

  get dataProperties() {
    return {
      addFiles: {},
      onClose: {},
      getTextLabel: {std: t => t},
      getButtonClassName: {std: t => t}
    };
  }

  static get properties() {
    return {
      error: {type: 'str', std: null},
      disabled: {type: 'bool', std: false}
    };
  }

  destroy() {
    this.stopVideoStream();
    super.destroy();
  }

  // -------
  // Private
  // -------

  getVideo() {
    return this.dom_node.querySelector('.px-ud-camera-preview video');
  }

  // --------------
  // Event handlers
  // --------------

  onClose(evt) {
    this.stopVideoStream();
    this.data.onClose();
  }

  startVideoStream() {
    const video = this.getVideo();

    if (!(navigator && navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
      this.state.error = 'Your browser does not have access to the camera';
    } else {
      const constraints = {
        audio: false,
        video: {
          facingMode: 'user',
          width: 3840,
          height: 2880
        }
      };
      navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
        video.srcObject = stream;
        video.play();

      }).catch((err) => {
        console.error(`An error occurred: ${err}`);
        this.state.error = err;
      });
    }
  }

  stopVideoStream() {
    const video = this.getVideo();
    if (video.srcObject) {
      video.srcObject.getTracks().forEach(track => track.stop());
    }
  }

  captureCameraImage() {
    this.state.disabled = true;

    const video = this.getVideo();
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    this.stopVideoStream();

    canvas.toBlob(blob => {
      if (!blob) {
        this.state.error = 'Failed to capture image';
      } else {
        const file = new File([blob], 'camera-capture.jpg', {type: 'image/jpeg'});
        const attrs = {
          data: {file: file},
          // Local files always have a computable length, make sure to set lengthComputable to `true`
          // immediately when adding new files so that progress bar doesn't keep alternating between
          // determinate and indeterminate versions.
          uploadProgress: {
            lengthComputable: true,
            total: file.size,
            loaded: 0
          }
        };
        this.data.addFiles([['camera', file.name, attrs]]);
      }
    }, 'image/jpeg', 0.95);
  }

};
