Px.CMS.ImageUpload = class ImageUpload extends HTMLElement {
  static get observedAttributes() {
    return [
      'name',
      'value',
      'required',
      'disabled',
      'img-width',
      'img-height',
      'img-src',
      'thumbnail',
      'crop-aspect-ratio',
      'crop-rotation-mode',
      'minimum-dpi',
      'upload-button-class',
      'upload-button-text',
      'adjust-button-class',
      'adjust-button-text',
      'data-px-no-element-substitutions',
      'data-px-no-pricing'
    ];
  }

  get pxNoRerender() {
    return 'children';
  }

  constructor() {
    super();

    this.DEFAULT_UPLOAD_BUTTON_TEXT = 'Upload Image';
    this.DEFAULT_ADJUST_BUTTON_TEXT = 'Adjust';
    this.DEFAULT_RESOLUTION_WARNING_TEXT = 'Image resolution is low.';
    this.DEFAULT_ERROR_MESSAGES = {
      required: 'Please select an image'
    };
  }

  connectedCallback() {
    this.uploadButton = this.makeUploadButton();
    this.adjustButton = this.makeAdjustButton();
    this.thumbnailDiv = this.makeThumbnail();
    this.resolutionWarningDiv = this.makeResolutionWarning();
    this.hiddenInput = this.makeHiddenInput();
    this.uploadDialog = null;

    this.setButtonVisibility();
    this.setButtonValidity();
    this.setResolutionWarningVisibility();
    this.refreshThumbnail();

    this.append(this.thumbnailDiv);
    this.append(this.uploadButton);
    this.append(this.adjustButton);
    this.append(this.resolutionWarningDiv);
    this.append(this.hiddenInput);
  }

  disconnectedCallback() {
    if (this.uploadDialog) {
      this.uploadDialog.destroy();
    }
    this.innerHTML = '';
    this.thumbnailDiv = null;
    this.uploadButton = null;
    this.adjustButton = null;
    this.resolutionWarningDiv = null;
    this.hiddenInput = null;
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (this.isConnected) {
      switch (name) {
      case 'name':
        if (this.hiddenInput) {
          this.hiddenInput.name = newValue;
        }
        break;
      case 'value':
        if (oldValue !== newValue) {
          this.value = newValue;
          this.setButtonVisibility();
          this.setButtonValidity();
          this.setResolutionWarningVisibility();
          this.refreshThumbnail();
        }
        break;
      case 'required':
        if (oldValue !== newValue) {
          if (newValue === null) {
            this.removeAttribute('required');
          } else {
            this.setAttribute('required', newValue);
          }
          this.setButtonValidity();
        }
        break;
      case 'disabled':
        if (oldValue !== newValue) {
          if (newValue === null) {
            this.removeAttribute('disabled');
          } else {
            this.setAttribute('disabled', newValue);
          }
          this.setButtonVisibility();
          if (this.hiddenInput) {
            this.hiddenInput.disabled = this.disabled || !this.value;
          }
        }
        break;
      case 'thumbnail':
      case 'img-src':
        if (oldValue !== newValue) {
          this.refreshThumbnail();
        }
        break;
      case 'img-width':
      case 'img-height':
      case 'crop-aspect-ratio':
      case 'crop-rotation-mode':
      case 'minimum-dpi':
        this.setResolutionWarningVisibility();
        break;
      case 'upload-button-class':
        if (this.uploadButton) {
          this.uploadButton.className = newValue;
        }
        break;
      case 'upload-button-text':
        if (this.uploadButton) {
          this.uploadButton.innerText = newValue || this.DEFAULT_UPLOAD_BUTTON_TEXT;
        }
        break;
      case 'adjust-button-class':
        if (this.adjustButton) {
          this.adjustButton.className = newValue;
        }
        break;
      case 'adjust-button-text':
        if (this.adjustButton) {
          this.adjustButton.innerText = newValue || this.DEFAULT_ADJUST_BUTTON_TEXT;
        }
        break;
      case 'data-px-no-element-substitutions':
      case 'data-px-no-pricing':
        if (this.hiddenInput) {
          if (newValue === null) {
            this.hiddenInput.removeAttribute(name);
          } else {
            this.hiddenInput.setAttribute(name, newValue);
          }
        }
        break;
      }
    }
  }

  get value() {
    return this.getAttribute('value') || '';
  }

  set value(value) {
    if (value === null) {
      if (this.hiddenInput) {
        this.hiddenInput.removeAttribute('value');
        this.hiddenInput.disabled = true;
      }
      this.removeAttribute('value');
    } else {
      if (this.hiddenInput) {
        this.hiddenInput.value = value;
        this.hiddenInput.disabled = this.disabled || !value;
      }
      this.setAttribute('value', value);
    }
  }

  get required() {
    return this.hasAttribute('required');
  }

  set disabled(value) {
    if (value === false) {
      this.removeAttribute('disabled');
    } else {
      this.setAttribute('disabled', '');
    }
  }

  get disabled() {
    return this.hasAttribute('disabled');
  }

  get uploadInProgress() {
    return !!this._uploadInProgress;
  }

  set uploadInProgress(value) {
    this._uploadInProgress = !!value;
    this.setButtonVisibility();
  }

  makeUploadButton() {
    const button = document.createElement('button');

    button.innerText = this.getAttribute('upload-button-text') || this.DEFAULT_UPLOAD_BUTTON_TEXT;

    if (this.hasAttribute('upload-button-class')) {
      button.className = this.getAttribute('upload-button-class');
    }

    button.addEventListener('click', (evt) => {
      evt.preventDefault();

      this.uploadDialog = this.makeUploadDialog();

      this.uploadDialog.addEventListener('upload-start', this.onUploadStart.bind(this));
      this.uploadDialog.addEventListener('upload-error', this.onUploadError.bind(this));
      this.uploadDialog.addEventListener('upload-success', (evt) => this.onUploadSuccess(evt));
      this.uploadDialog.addEventListener('cancel', () => this.uploadInProgress = false);

      document.body.appendChild(this.uploadDialog);
    });

    new ResizeObserver(this.resizeThumbnailDiv.bind(this)).observe(button);

    return button;
  }

  makeAdjustButton() {
    const button = document.createElement('button');

    button.innerText = this.getAttribute('adjust-button-text') || this.DEFAULT_ADJUST_BUTTON_TEXT;

    if (this.hasAttribute('adjust-button-class')) {
      button.className = this.getAttribute('adjust-button-class');
    }

    button.addEventListener('click', (evt) => {
      evt.preventDefault();
      this.makeImageAdjustTool();
    });

    return button;
  }

  makeImageAdjustTool() {
    const overlay = document.createElement('div');
    overlay.className = 'px-image-upload-crop-modal-overlay';

    const tool = document.createElement('px-image-adjust-tool');
    tool.className = 'px-image-upload-crop-modal-wrapper';
    tool.setAttribute('value', this.value);
    tool.setAttribute('img-src', this.getAttribute('img-src'));

    if (this.hasAttribute('img-width')) {
      tool.setAttribute('img-width', this.getAttribute('img-width'));
    }
    if (this.hasAttribute('img-height')) {
      tool.setAttribute('img-height', this.getAttribute('img-height'));
    }

    if (this.hasAttribute('minimum-dpi')) {
      tool.setAttribute('minimum-dpi', this.getAttribute('minimum-dpi'));
    }
    if (this.hasAttribute('crop-aspect-ratio')) {
      tool.setAttribute('crop-aspect-ratio', this.getAttribute('crop-aspect-ratio'));
    }
    if (this.hasAttribute('crop-shrink-to-fit')) {
      tool.setAttribute('shrink-to-fit', '');
    }
    if (this.hasAttribute('crop-rotation-mode')) {
      tool.setAttribute('rotation-mode', this.getAttribute('crop-rotation-mode'));
    }

    // TODO: Rename crop-* to adjust-tool-*
    if (this.hasAttribute('crop-rotate-right-button-text')) {
      tool.setAttribute('rotate-right-button-text', this.getAttribute('crop-rotate-right-button-text'));
    }
    if (this.hasAttribute('crop-rotate-left-button-text')) {
      tool.setAttribute('rotate-left-button-text', this.getAttribute('crop-rotate-left-button-text'));
    }

    if (this.hasAttribute('crop-cancel-button-text')) {
      tool.setAttribute('cancel-button-text', this.getAttribute('crop-cancel-button-text'));
    }
    if (this.hasAttribute('crop-submit-button-text')) {
      tool.setAttribute('submit-button-text', this.getAttribute('crop-submit-button-text'));
    }

    if (this.hasAttribute('crop-button-class')) {
      tool.setAttribute('button-class', this.getAttribute('crop-button-class'));
    }
    if (this.hasAttribute('crop-edit-button-class')) {
      tool.setAttribute('edit-button-class', this.getAttribute('crop-edit-button-class'));
    }
    if (this.hasAttribute('crop-action-button-class')) {
      tool.setAttribute('action-button-class', this.getAttribute('crop-action-button-class'));
    }

    if (this.hasAttribute('crop-rotate-right-button-class')) {
      tool.setAttribute('rotate-right-button-class', this.getAttribute('crop-rotate-right-button-class'));
    }
    if (this.hasAttribute('crop-rotate-left-button-class')) {
      tool.setAttribute('rotate-left-button-class', this.getAttribute('crop-rotate-left-button-class'));
    }
    if (this.hasAttribute('crop-cancel-button-class')) {
      tool.setAttribute('cancel-button-class', this.getAttribute('crop-cancel-button-class'));
    }
    if (this.hasAttribute('crop-submit-button-class')) {
      tool.setAttribute('submit-button-class', this.getAttribute('crop-submit-button-class'));
    }

    tool.addEventListener('cancel', () => {
      document.body.removeChild(overlay);
    });

    tool.addEventListener('confirm', evt => {
      this.value = tool.value;
      document.body.removeChild(overlay);
    });

    overlay.appendChild(tool);
    document.body.prepend(overlay);
  }

  makeThumbnail() {
    const div = document.createElement('div');
    div.className = 'px-thumbnail';
    div.hidden = !this.hasAttribute('thumbnail');

    return div;
  }

  makeResolutionWarning() {
    const div = document.createElement('div');
    div.className = 'px-resolution-warning';
    div.hidden = true;

    div.innerText = this.getAttribute('resolution-warning-text') || this.DEFAULT_RESOLUTION_WARNING_TEXT;

    return div;
  }

  makeHiddenInput() {
    const input = document.createElement('input');

    input.name = this.getAttribute('name');
    input.type = 'hidden';
    input.value = this.value;
    input.disabled = this.disabled || !this.value;

    if (this.hasAttribute('data-px-no-element-substitutions')) {
      input.setAttribute('data-px-no-element-substitutions', this.getAttribute('data-px-no-element-substitutions'));
    }

    if (this.hasAttribute('data-px-no-pricing')) {
      input.setAttribute('data-px-no-pricing', this.getAttribute('data-px-no-pricing'));
    }

    return input;
  }

  makeUploadDialog() {
    const dialog = document.createElement('px-upload-dialog');

    const mapped_attributes = [
      'sources',
      'max-size',
      'gallery-id',
      'error-message-filesize',
      'error-message-upload'
    ];

    const prefixed_attributes = [
      'upload-dialog-button-class',
      'upload-dialog-source-button-class',
      'upload-dialog-local-source-button-class',
      'upload-dialog-galleries-source-button-class',
      'upload-dialog-qr-source-button-class',
      'upload-dialog-url-source-button-class',
      'upload-dialog-confirm-button-class',
      'upload-dialog-picker-load-more-button-class',
      'upload-dialog-main-panel-title',
      'upload-dialog-galleries-picker-title',
      'upload-dialog-picker-load-more-button-title',
      'upload-dialog-picker-ok-button-title',
      'upload-dialog-url-picker-title',
      'upload-dialog-back-button-title',
      'upload-dialog-close-button-title',
      'upload-dialog-source-button-local-text',
      'upload-dialog-source-button-galleries-text',
      'upload-dialog-source-button-url-text',
      'upload-dialog-qr-picker-info-text',
      'upload-dialog-no-galleries-text',
      'upload-dialog-no-images-text'
    ];

    mapped_attributes.forEach(attribute => {
      if (this.hasAttribute(attribute)) {
        dialog.setAttribute(attribute, this.getAttribute(attribute));
      }
    });

    prefixed_attributes.forEach(prefixed_attribute => {
      const attribute = prefixed_attribute.replace('upload-dialog-', '');
      if (this.hasAttribute(prefixed_attribute)) {
        dialog.setAttribute(attribute, this.getAttribute(prefixed_attribute));
      }
    });

    return dialog;
  }

  resizeThumbnailDiv() {
    if (this.uploadButton && this.thumbnailDiv) {
      const rect = this.uploadButton.getBoundingClientRect();
      this.thumbnailDiv.style.setProperty('--available-height', `${rect.height}px`);
    }
  }

  setButtonVisibility() {
    if (this.uploadButton) {
      this.uploadButton.disabled = this.disabled || this.uploadInProgress;
    }
    if (this.adjustButton) {
      this.adjustButton.disabled = (this.disabled || this.uploadInProgress || !this.value);
      this.adjustButton.hidden = !this.value;
    }
  }

  // Button elements support native JS validation (setCustomValidity only).
  // Because we're not using any other visible UI form elements for image upload
  // (the inpu[type=file] is hidden), we set validity message on the buttons.
  // When ElementInternals is more widely supported, we might want to switch to using it instead:
  // https://developer.mozilla.org/en-US/docs/Web/API/ElementInternals
  setButtonValidity(errmsg) {
    if (this.uploadButton) {
      let errmsg = '';
      if (this.required && !this.value) {
        errmsg = this.getErrorMessage('required');
      }
      this.uploadButton.setCustomValidity(errmsg);
    }
  }

  refreshThumbnail() {
    if (!this.thumbnailDiv) {
      return;
    }

    const img_src = this.getAttribute('img-src');
    if (img_src && this.value && this.hasAttribute('thumbnail')) {
      this.thumbnailDiv.style.backgroundImage = `url('${img_src}')`;
      this.thumbnailDiv.hidden = false;
    } else {
      this.thumbnailDiv.hidden = true;
    }

    this.resizeThumbnailDiv();
  }

  setResolutionWarningVisibility() {
    if (!this.resolutionWarningDiv) {
      return;
    }

    const image_pixel_width = parseInt(this.getAttribute('img-width'), 10);
    const image_pixel_height = parseInt(this.getAttribute('img-height'), 10);
    const crop_data = Object.assign({l: 0, t: 0, r: 0, z: 0}, Px.CMS.Helpers.parseImageValue(this.value).crop_data);
    const crop_ar_str = this.getAttribute('crop-aspect-ratio');
    const minimum_dpi = parseFloat(this.getAttribute('minimum-dpi'));
    const rotation_mode = this.getAttribute('crop-rotation-mode') || 'image';

    const is_low_res = Px.CMS.Helpers.isLowResolution(
      image_pixel_width, image_pixel_height, crop_data, crop_ar_str, minimum_dpi, rotation_mode
    );

    this.resolutionWarningDiv.hidden = !is_low_res;
  }

  convertMaxSizeToBytes(maxSize) {
    maxSize = maxSize.toString();

    const maxSizeStrNumber = +maxSize.match(/[+-]?\d+(\.\d+)?/g)[0];
    const maxSizeStrLetters = maxSize.match(/[a-zA-Z]+/g);
    const maxSizeUnit = maxSizeStrLetters ? maxSizeStrLetters[0] : 'b';

    let maxSizeKilobytes;

    switch (maxSizeUnit) {
    case 'm':
      maxSizeKilobytes = maxSizeStrNumber * 1024 * 1024;
      break;
    case 'k':
      maxSizeKilobytes = maxSizeStrNumber * 1024;
      break;
    default:
      maxSizeKilobytes = maxSizeStrNumber;
    }

    return maxSizeKilobytes;
  }

  getErrorMessage(key) {
    return this.getAttribute(`error-message-${key}`) || this.DEFAULT_ERROR_MESSAGES[key];
  }

  serializeValue(valueDict) {
    let value = '';
    if (valueDict.imgId) {
      value = `db:${valueDict.imgId}`;
      if (valueDict.cropData) {
        let cropParts = [];
        ['l', 't', 'r', 'z'].forEach((key) => {
          let val = valueDict.cropData[key];
          if (val) {
            cropParts.push(`${key}:${val}`);
          }
        });
        if (cropParts.length > 0) {
          value += `@{${cropParts.join(',')}}`;
        }
      }
    }
    return value;
  }

  onUploadSuccess(evt) {
    const apiResponse = evt.detail.files[0].result;
    this.uploadInProgress = false;
    this.value = this.serializeValue({imgId: apiResponse.id});
    const imgSrc = apiResponse.thumbnails[apiResponse.thumbnails.length - 1].url;
    this.setAttribute('img-src', imgSrc);
    this.setAttribute('img-width', apiResponse.width);
    this.setAttribute('img-height', apiResponse.height);
    // Preload the image so that it displays immediately after opening the cropping modal.
    new Image().src = imgSrc;
  }

  onUploadError(evt) {
    this.uploadInProgress = false;
  }

  onUploadStart(evt) {
    this.uploadInProgress = true;
  }

};

customElements.define('px-image-upload', Px.CMS.ImageUpload);
